define("boondmanager/pods/components/octane/bm-app/component/widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YsxGYZBG",
    "block": "{\"symbols\":[\"@app\",\"@fn\",\"@component\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-app-component-widget\"],[17,4],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,0],[[32,0,[\"currentUser\",\"isAdministrator\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,0,[\"isAppLoaded\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"isNative\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\t\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-icon\"],[16,\"data-original-title\",[32,1,[\"name\"]]]],[[\"@onClick\",\"@iconOnly\"],[[30,[36,1],[[32,0,[\"setIsOpened\"]],[30,[36,0],[[32,0,[\"isOpened\"]]],null]],null],[32,1,[\"icon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\\t\\t\\t\"],[8,\"octane/bm-btn\",[[24,0,\"bmb-circle bmb-circle-icon-large bmb-large bmc-app-component-widget_logo\"],[16,\"data-original-title\",[32,1,[\"name\"]]]],[[\"@onClick\"],[[30,[36,1],[[32,0,[\"setIsOpened\"]],[30,[36,0],[[32,0,[\"isOpened\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\"],[1,[30,[36,2],[[32,1,[\"logo\"]]],null]],[2,\"\\n\\t\\t\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\t\\t\"],[10,\"div\"],[15,0,[31,[\"bmc-app-component-widget_wrapper \",[30,[36,3],[[32,0,[\"isOpened\"]],\"active\"],null]]]],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"bmc-app-component-widget_content\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[1,[30,[36,4],[[32,3]],[[\"app\",\"settings\",\"fn\",\"actionID\",\"isWidget\",\"isOpened\",\"setIsOpened\",\"onAppLoaded\"],[[32,1],[32,1,[\"settings\"]],[32,2],[32,1,[\"actionID\"]],true,[32,0,[\"isOpened\"]],[32,0,[\"setIsOpened\"]],[32,0,[\"appLoaded\"]]]]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\" \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"fn\",\"img-absolute\",\"if\",\"component\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-app/component/widget/template.hbs"
  });
});