define("boondmanager/pods/private/apps/ringoverbyboond/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dwar6OTp",
    "block": "{\"symbols\":[\"__arg0\",\"__arg0\"],\"statements\":[[8,\"octane/bm-layout-profile\",[],[[\"@withFooter\",\"@title\",\"@icon\",\"@namedBlocksInfo\"],[false,[30,[36,0],[\"ringoverbyboond:title\"],null],\"bmi-app-ringoverbyboond\",[30,[36,3],null,[[\"content\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,1],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\"],[8,\"octane/bm-alerts\",[],[[\"@type\"],[\"info\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\"],[1,[30,[36,0],[\"ringoverbyboond:view.info\"],null]],[2,\"\\n\\t\\t\"]],\"parameters\":[]}]]],[2,\"\\n\\n\\t\\t\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\\t\"],[10,\"div\"],[14,0,\"col-xl-6 col-12\"],[12],[2,\"\\n\\t\\t\\t\\t\"],[8,\"octane/bm-layout-profile/section\",[[24,\"data-name\",\"section-generalInformation\"]],[[\"@namedBlocksInfo\"],[[30,[36,3],null,[[\"header\",\"content\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"ringoverbyboond:view.generalInformation.title\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"content\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[30,[36,0],[\"ringoverbyboond:view.generalInformation.content\"],null]],[2,\"\\n\\t\\t\\t\\t\\t\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n\\t\\t\\t\"],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "boondmanager/pods/private/apps/ringoverbyboond/template.hbs"
  });
});