define("boondmanager/pods/components/octane/bm-app/component/iframe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HlcNOXta",
    "block": "{\"symbols\":[\"&attrs\",\"@app\",\"@isOpened\"],\"statements\":[[11,\"div\"],[24,0,\"bmc-app-component_iframe\"],[17,1],[4,[38,0],[[32,0,[\"didReceiveAttrs\"]]],null],[4,[38,1],[[32,0,[\"didReceiveAttrs\"]],[32,2]],null],[4,[38,1],[[32,0,[\"toggleWidget\"]],[32,3]],null],[4,[38,2],[[32,0,[\"didDestroyElement\"]]],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"iFrameUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"iframe\"],[15,1,[32,0,[\"iFrameID\"]]],[15,3,[32,0,[\"iFrameID\"]]],[15,\"src\",[32,0,[\"iFrameUrl\"]]],[15,\"scrolling\",[32,0,[\"iFrameScrolling\"]]],[14,\"marginwidth\",\"0\"],[14,\"marginheight\",\"0\"],[14,\"frameborder\",\"0\"],[15,5,[32,0,[\"iFrameStyle\"]]],[15,\"allow\",[32,0,[\"iFrameFeaturesAllowed\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"did-update\",\"will-destroy\",\"if\"]}",
    "moduleName": "boondmanager/pods/components/octane/bm-app/component/iframe/template.hbs"
  });
});